exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-dlb-tsx": () => import("./../../../src/pages/about-dlb.tsx" /* webpackChunkName: "component---src-pages-about-dlb-tsx" */),
  "component---src-pages-apartment-view-dato-cms-apartment-slug-tsx": () => import("./../../../src/pages/apartment-view/{DatoCmsApartment.slug}.tsx" /* webpackChunkName: "component---src-pages-apartment-view-dato-cms-apartment-slug-tsx" */),
  "component---src-pages-apartments-dato-cms-project-slug-tsx": () => import("./../../../src/pages/apartments/{DatoCmsProject.slug}.tsx" /* webpackChunkName: "component---src-pages-apartments-dato-cms-project-slug-tsx" */),
  "component---src-pages-apartments-index-tsx": () => import("./../../../src/pages/apartments/index.tsx" /* webpackChunkName: "component---src-pages-apartments-index-tsx" */),
  "component---src-pages-commercial-tsx": () => import("./../../../src/pages/commercial.tsx" /* webpackChunkName: "component---src-pages-commercial-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

