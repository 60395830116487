module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DLB Living","short_name":"DLB","start_url":"/","background_color":"#fff","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5e38349eadbe65e4e3c55ec910ef530c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","ro"],"defaultLanguage":"en","localeJsonSourceName":"locale","siteUrl":"https://www.dlb-living.ro/","i18nextOptions":{"defaultNS":"common","lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"nsSeparator":false},"pages":[{"matchPath":"/:lang?/apartments/:slug","getLanguageFromPath":false},{"matchPath":"/:lang?/apartment-view/:id","getLanguageFromPath":false}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
